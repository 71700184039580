<template>
  <div class="article-detail">
    <div id="toolbar" class="toolbar">
      <el-card class="box-card">
        <div class="section-logo">
          <img :src="logoImg" />
        </div>
      </el-card>

      <customer-info
        v-if="type == 'submission'"
        :type="type"
        class="box-card"
      ></customer-info>

      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>Edit History</span>
        </div>
        <div class="el-row box-card-con">No Data</div>
      </el-card>
    </div>
    <div class="editor">
      <el-input
        disabled
        v-model="title"
        type="textarea"
        placeholder=""
        :autosize="{ minRows: 1 }"
        class="editor-title"
      ></el-input>
      <div id="editor" class="editor-content" ref="editor"></div>
    </div>
    <div class="extended">
      <div>
        <el-button
          class="save-button"
          :style="{ borderColor: headerColor }"
          @click="goBack"
          >Return</el-button
        >
      </div>
      <div>
        <el-button
          class="save-button"
          :style="{ borderColor: headerColor }"
          v-if="type == 'submission'"
          @click="reviewArticle"
          >Review</el-button
        >
      </div>
      <div>
        <el-button
          class="save-button"
          :style="{ borderColor: headerColor }"
          v-if="type == 'submission'"
          >Save Score</el-button
        >
      </div>
      <el-card class="box-card" v-if="type == 'submission'">
        <score-table></score-table>
      </el-card>
      <el-card class="box-card">
        <div slot="header" class="clearfix">Editorial Feedback</div>
        <div class="box-card-con">
          <span>No Data</span>
        </div>
      </el-card>
    </div>
    <review-modal v-model="reviewVisible"></review-modal>
  </div>
</template>

<script>
import { getArticle } from "@/api";
import { sectionList } from "../utils/commonList";
import customerInfo from "../components/customerInfo.vue";
import reviewModal from "../components/reviewModal.vue";
import scoreTable from "../components/scoreTable.vue";
import { mapActions, mapState } from "vuex";

// ******************************quill ************************************************
import Quill from "quill";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.core.css";
// ******************************quill ************************************************
export default {
  name: "articleDetail",
  components: {
    customerInfo,
    reviewModal,
    scoreTable,
  },
  props: {},
  data() {
    let type = this.$route.query.type;
    return {
      title: "",
      logoImg: "",
      sectionList: sectionList,
      reviewVisible: false,
      type: type,
    };
  },
  computed: {
    ...mapState(["headerColor"]),
  },
  watch: {},
  async mounted() {
    this.initEditor();
    if (this.$route.query.sectionType) {
      const _sectionType = Number(this.$route.query.sectionType);
      console.log(this.sectionList);
      const _list = this.sectionList.filter((i) => i.value === _sectionType)[0];
      this.logoImg = _list.img;
      this.updateHeaderColor(_list.color);
    }

    const id = this.$route.query.id;
    if (!id) return;
    await this.queryEditArticle(id);
  },
  methods: {
    ...mapActions(["updateHeaderColor"]),
    async queryEditArticle(id) {
      const { data } = await getArticle(id);
      if (data.code === 0) {
        let val = data.data;
        if (!val) return;
        this.quill.clipboard.dangerouslyPasteHTML(val.content);
        this.editData = val;
        this.title = val.title;
      } else {
        this.$message.error(data.msg);
      }
    },
    initEditor() {
      this.quill = new Quill("#editor", {
        theme: "snow",
        modules: {
          toolbar: {
            container: "#toolbar",
          },
        },
      });
      this.quill.enable(false);
    },
    goBack() {
      this.$router.back();
    },
    reviewArticle() {
      this.reviewVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.article-detail {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 90px);
  //padding: 30px 20px;
  background-color: #f2f4f9;
  font-size: 12px;

  .toolbar {
    flex: 1;
    margin-right: 20px;
    border: none;
    padding: 0;
    .box-card {
      margin-bottom: 12px;
      overflow: inherit;
      box-shadow: none;
      border-radius: 8px;
      &-con {
        display: flex;
        height: 40px;
        align-items: center;
        justify-content: center;
      }
    }
  }
  .editor {
    flex: 3;
    margin-right: 20px;
    background-color: #fff;
    padding: 30px;
    overflow-y: auto;
    border: none;
    border-radius: 8px;
    &-content {
      flex: 3;
      margin-right: 20px;
      background-color: #fff;
      overflow-y: auto;
      border: none;
      border-radius: 8px;
    }
    &-title {
      font-size: 28px;
      line-height: 32px;
      border-bottom: 1px solid #eff3f6;
      padding-bottom: 24px;
      /deep/.el-textarea__inner {
        border: none;
        color: #000000;
        font-family: GravityNormal;
      }
    }
    /deep/ .el-textarea__inner {
      background: none;
    }
  }
  .extended {
    flex: 1;
    .box-card {
      margin-top: 20px;
      margin-bottom: 12px;
      overflow: inherit;
      box-shadow: none;
      border-radius: 8px;
      .clearfix {
        font-size: 12px;
      }
      &-con {
        display: flex;
        height: 40px;
        align-items: center;
        justify-content: center;
      }
    }
    /deep/ .save-button {
      width: 100%;
      margin-top: 10px;
      color: #181818;
      border: 1px solid #181818;
      font-size: 14px;
      background-color: #f2f4f9;
    }
  }
}
</style>
