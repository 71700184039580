<template>
  <div class="score-table">
    <el-table
      :data="tableData"
       :span-method="arraySpanMethod"
      border
      width="100%"
      :header-cell-style="{ background: '#FF6A64', color: '#ffffff' }"
    >
      <el-table-column prop="Criteria" label="Criteria" min-width="150">
      </el-table-column>
      <el-table-column prop="Mx" label="Mx" min-width="70">
      </el-table-column>
      <el-table-column prop="Score" label="Score" min-width="80">
        <template slot-scope="scope">
          <el-input v-model="scope.row.score" type="number"></el-input>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "scoreTable",
  components: {
  },
  data() {
    return {
      tableData: [
        {
          Criteria: "Innovation",
          Mx: 10,
          Score: '',
        },
        {
          Criteria: "Layout",
          Mx: 20,
          Score: '',
        },
        {
          Criteria: "Significance",
          Mx: 10,
          Score: '',
        },
        {
          Criteria: "Total Score",
          Mx: "",
          Score: '',
        },
      ],
    };
  },
  methods: {
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (rowIndex === this.tableData.length - 1) {
        if (columnIndex === 0) {
          return [1, 2];
        } else if (columnIndex === 1) {
          return [0, 0];
        }
      }
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>

</style>
