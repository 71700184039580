<template>
  <el-dialog
    class="review-dialog"
    title="Review Article"
    :visible.sync="visible"
    width="50%"
  >
    <div class="review-dialog-content">
      <el-form
          ref="reviewForm"
          :model="reviewArticle"
          :rules="rules"
          label-position="top"
          label-width="150px"
          status-icon
        >
          <el-form-item prop="review_type" label="Review Decision: " class="review-decision">
            <el-radio-group v-model="reviewArticle.review_type">
              <el-radio :label="1">Accept</el-radio>
              <el-radio :label="2">Reject</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item prop="review_feedback" label="Review Feedback: ">
            <el-input
              v-model="reviewArticle.review_feedback"
              type="textarea"
              placeholder="Please input Review Feedback"
              :autosize="{ minRows: 3 }"
            ></el-input>
          </el-form-item>
        </el-form>
    </div>

    <span slot="footer" class="review-dialog-footer">
      <el-button type="primary" @click="visible = false">Cancel</el-button>
      <el-button type="primary" @click="handleSubmit">Submit</el-button>
    </span>
  </el-dialog>
</template>
<script>

export default {
name: "reviewModal",
data() {
  return {
    visible: this.value,
    checked: false,
    reviewArticle: {
      review_type: null,
      review_feedback: ''
    },
    rules: {
      review_type: [
        { required: true, message: "Please select Review Decision", trigger: "blur" },
      ],
      review_feedback: [
        { required: true, message: "Please input Review Feedback", trigger: "blur" },
        { max: 150, message: '长度不得超过150字符', trigger: 'blur' }
      ],
      },
  };
},
props: {
  value: {
    type: Boolean
  },
},
watch: {
  value(n, o) {
    this.visible = n;
  },
  visible(n, o) {
    this.$emit('input', n)
  }
},
mounted() {
},
methods: {
  handleSubmit() {
    this.$refs.reviewForm.validate((val) => {
        if (val) {
          this.visible = false
        }
      });
  }
},
};
</script>


<style lang="scss" scoped>
.review-dialog {
/deep/.el-dialog__header {
  text-align: center;
  .el-dialog__title {
    color: #eb7269;
    font-family: GravityBold;
  }
}
/deep/.el-dialog__footer {
  text-align: center;
}

&-content {
  /deep/ .review-decision {
    display: flex;
    .el-form-item__label {
      margin-right: 20px;
    }
  }  
}
}
</style>
